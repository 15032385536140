interface SocialNetwork {
  name: string;
  pictoSrc: string;
  link: string;
}

const socialNetworks: SocialNetwork[] = [
  {
    name: "Linkedin",
    pictoSrc: "/linkedin.svg",
    link: "https://www.linkedin.com/company/bivouac-io/",
  },
  // {
  //   name: "Facebook",
  //   pictoSrc: "/facebook-square.svg",
  //   link: "https://www.facebook.com/bivouac.io",
  // },
];

export default socialNetworks;
