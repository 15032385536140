import { RefObject, useEffect, useState } from "react";

export default function useAnimate(
  classMasked: string,
  targetRef: RefObject<HTMLElement>,
  anchorRef?: RefObject<HTMLElement>
) {
  const [isTriggered, setIsTriggered] = useState<boolean>(false);

  useEffect(() => {
    // Disable the animation if the user wants to disable animations
    const shouldReduceMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    );
    if (shouldReduceMotion.matches) {
      return;
    }
    if (anchorRef === undefined) {
      anchorRef = targetRef;
    }
    if (targetRef.current && anchorRef.current) {
      // Mask the element as initial state
      !isTriggered && targetRef.current.classList.add(classMasked);
      var intersectionObserver = new IntersectionObserver(function (entries) {
        // If intersectionRatio is 0, the target is out of view
        // and we do not need to do anything.
        if (entries[0].intersectionRatio <= 0) return;
        if (targetRef.current) {
          targetRef.current.classList.remove(classMasked);
          setIsTriggered(true);
        }
      });
      // start observing
      intersectionObserver.observe(anchorRef.current);
    } else {
      console.warn("useAnimate : A ref has no current element");
    }
  }, [targetRef]);
}
