import React, { createRef, FC, RefObject } from "react";
import cx from "classnames";
import useAnimate from "../hooks/useAnimate";
import classes from "../styles/components/ConceptionAnimate.module.scss";

interface IConceptionAnimateTextProps {
  type?: "text" | "image";
}

const ConceptionAnimateText: FC<IConceptionAnimateTextProps> = (props) => {
  const refAnimationWrap: RefObject<HTMLDivElement> = createRef();

  useAnimate(classes["masked"], refAnimationWrap);

  return props.type === "text" ? (
    <div ref={refAnimationWrap} className={cx(classes["wrap-text"])}>
      {props.children}
    </div>
  ) : (
    <div ref={refAnimationWrap} className={cx(classes["wrap-image"])}>
      {props.children}
      <div className={classes["mask"]}>
        <div className={classes["mask__line"]} />
        <div className={classes["mask__line"]} />
      </div>
    </div>
  );
};

ConceptionAnimateText.defaultProps = {
  type: "text",
};

export default ConceptionAnimateText;
