import { FC, useEffect } from "react";
import Cal, { getCalApi } from "@calcom/embed-react";

export const Calcom: FC = () => {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: { branding: { brandColor: "#000000" } },
        hideEventTypeDetails: false,
        layout: "month_view",
      });
    })();
  }, []);
  return (
    <Cal
      calLink="team/bivouac/premier-contact"
      style={{ width: "100%", height: "100%", overflow: "scroll" }}
      config={{ layout: "month_view" }}
      calOrigin="https://cal.bivouac.io"
      // @ts-ignore
      calJsUrl="https://cal.bivouac.io/embed/embed.js"
    />
  );
};
