import React, { createRef, FC, RefObject, useEffect } from "react";
import { useMedia } from "react-use";
import cx from "classnames";
import Rellax from "rellax";
import { ContentBanner } from "../models/content.model";
import classes from "../styles/components/BannerParallax.module.scss";

interface IBannerParallaxProps {
  contentBanner: ContentBanner;
}

/**
 * Component for displaying the website's first view,
 * which contains a parallax animation with many layers.
 * @param props
 */
const BannerParallax: FC<IBannerParallaxProps> = (props) => {
  // References
  const refBrightnessOverlay: RefObject<HTMLDivElement> = createRef();

  const refsLayers: RefObject<HTMLImageElement>[] = [];

  const layersCount = 7;

  Array(layersCount)
    .fill(0)
    .forEach((v, index) => (refsLayers[index] = createRef()));

  // Hooks
  const isMobile = useMedia("(max-width: 576px)");

  // State

  // Get the user's viewport height (100vh)
  let viewPortHeight;
  useEffect(() => {
    viewPortHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
  });

  /**
   * Handle scrol on the main div
   */
  const handleScroll = () => {
    if (refBrightnessOverlay?.current) {
      const currentPosition = window.pageYOffset;

      /**
       * Modifier for the main scroll animation coefficient.
       * The bigger, the quicker animations ends on scroll
       * */
      const coefficientModifier = 1.2;

      const coefficient =
        (coefficientModifier * currentPosition) / viewPortHeight;

      requestAnimationFrame(() => {
        refBrightnessOverlay.current.style.opacity = coefficient.toString();
      });
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
  }, [refBrightnessOverlay]);

  /**
   * Handle parallax
   * This is not the "React" way to to dit, but it works :D
   * It would be better to make the useEffect to listen on image elements.complete,
   * but I couldn't manage to make it work.
   */
  useEffect(() => {
    // Disable the animation if the user wants to disable animations
    const shouldReduceMotion = window.matchMedia(
      "(prefers-reduced-motion: reduce)"
    );
    if (shouldReduceMotion.matches) {
      return;
    }

    const rellax = new Rellax(".rellax", { breakpoints: [576, 768, 1200] });

    const interval = setInterval(() => {
      const areAllImagesLoaded = refsLayers.every(
        (ref) => ref.current?.complete
      );

      if (!areAllImagesLoaded) {
        rellax.refresh();
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => {
      rellax.destroy();
    };
  }, [isMobile]);

  return (
    <>
      <div className={classes["parallax-container"]}>
        {!isMobile ? (
          <>
            {new Array(layersCount).fill(0).map((v, index) => (
              <img
                key={`banner-plan-${index + 1}`}
                className={cx(
                  classes["layer"],
                  classes[`layer--${index + 1}`],
                  "rellax"
                )}
                src={`/banner/banner-plan-${index + 1}.svg`}
                data-rellax-speed={-index * 3}
                ref={refsLayers[index]}
                alt=""
              />
            ))}
            <div className={classes["background"]} />
            <img
              key="img-banner-sunsky"
              src="/banner/banner-sunsky.svg"
              className={cx(classes["layer"], classes["layer--sun"], "rellax")}
              data-rellax-speed={-20}
              alt=""
            />
          </>
        ) : (
          // Wrap the img in a div to avoid reinjecting mobile elements in desktop elements
          // This solves an issue with server-side/client-side rendering, not solved yet...
          <div>
            <img
              alt=""
              key="bg-static-mobile"
              src="/bg-static.webp"
              className={classes["background-static"]}
            />
          </div>
        )}
        <div
          ref={refBrightnessOverlay}
          className={classes["brightness-overlay"]}
        />
        <div className={cx(classes["header-wrap"])}>
          <div className={cx(classes["header"])}>
            <div
              className={cx(classes["header__main"], "rellax")}
              data-rellax-speed={-4}
              data-rellax-tablet-speed={2}
              data-rellax-xs-speed={-2}
            >
              <img
                src="/logo-1.svg"
                className={classes["header__logo"]}
                alt="Logo Bivouac"
              />
              <span className={classes["header__motto"]}>
                {props.contentBanner.motto}
              </span>
            </div>
            <div
              className={cx(classes["header__words"], "rellax")}
              data-rellax-speed={8}
              data-rellax-xs-speed={-2}
            >
              {props.contentBanner.words.map((word, key) => (
                <span key={`word-${key}`}>
                  {/* <InlineText name={`banner.words[${key}]`} />. */}
                  {word}.
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BannerParallax.defaultProps = {};

export default BannerParallax;
