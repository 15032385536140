import React, { FC, useRef, useState } from "react";
import cx from "classnames";
import { FiX } from "react-icons/fi";
import { TiLightbulb } from "react-icons/ti";
import { ContentStickyEcoresponsible } from "../models/content.model";
import useOutsideClick from "../services/useOutsideClick";
import classes from "../styles/components/StickyEcoresponsible.module.scss";

interface IStickyEcoresponsibleProps {
  stickyData: ContentStickyEcoresponsible;
}

const StickyEcoresponsible: FC<IStickyEcoresponsibleProps> = (props) => {
  // State
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [modalOpenedOnce, setModalOpenedOnce] = useState<boolean>(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (isModalVisible) setIsModalVisible(false);
  });

  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
    if (!modalOpenedOnce) setModalOpenedOnce(true);
  };

  return (
    <div ref={ref} className={classes["wrap"]}>
      <div
        className={
          modalOpenedOnce
            ? classes["button"]
            : cx(classes["button"], classes["button__flashing"])
        }
      >
        <button
          onClick={() => handleModal()}
          className={cx(
            classes["button__circle"],
            isModalVisible
              ? classes["button__circle-opened"]
              : classes["button__circle-closed"]
          )}
          aria-label={`${
            !isModalVisible ? "Afficher" : "Masquer"
          } les informations d'accessibilité et d'écoresponsabilité`}
        >
          {isModalVisible ? (
            <FiX className={classes["button__icon-close"]} />
          ) : (
            <TiLightbulb className={classes["button__icon"]} />
          )}
        </button>
      </div>
      <div
        className={cx(
          classes["modal"],
          isModalVisible ? classes["modal__opened"] : classes["modal__closed"]
        )}
      >
        <h3>{props.stickyData.title}</h3>
        <h4>{props.stickyData.subtitle}</h4>
        <div dangerouslySetInnerHTML={{ __html: props.stickyData.content }} />
      </div>
    </div>
  );
};

StickyEcoresponsible.defaultProps = {};

export default StickyEcoresponsible;
