import React, { ButtonHTMLAttributes, FC, ReactNode } from "react";
import cx from "classnames";
import classes from "../styles/components/Button.module.scss";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
}

const Button: FC<IButtonProps> = ({ children, className, ...rest }) => {
  return (
    <button className={cx(classes["button"], className)} {...rest}>
      {children}
    </button>
  );
};

Button.defaultProps = {};

export default Button;
