import React, { FC } from "react";
import classes from "../styles/components/Contact.module.scss";
import Button from "./Button";
import { Calcom } from "./Calcom";

interface IContactSectionProps {
  handleOpenChat: Function;
  description: string;
}

const Contact: FC<IContactSectionProps> = (props) => {
  return (
    <div className={classes["container"]}>
      <Calcom />
      <p>{props.description}</p>
      <Button onClick={() => props.handleOpenChat()}>Nous écrire</Button>
    </div>
  );
};

Contact.defaultProps = {};

export default Contact;
