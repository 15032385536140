import React, { FC } from "react";
import cx from "classnames";
import classes from "../styles/components/SvgColorPalette.module.scss";

interface ISvgColorPaletteProps {}

const SvgColorPalette: FC<ISvgColorPaletteProps> = (props) => {
  const renderColorPalette = (index: number) => {
    return (
      <svg
        className={cx(classes["palette"], classes[`palette-${index}`])}
        width="1024pt"
        height="1024pt"
        version="1.1"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={classes["color-border-1"]}
          d="m910.64 426.41c-15.356 0-449.01-0.3049-463.64-0.3049-4.7617 0-23.565 5.5409-23.565 21.305 0 16.565 0.68899 116.06 0.68899 123.61 0 10.44 12.147 26.061 25.722 26.061h460.8c14.133-0.0156 25.586-11.469 25.602-25.602v-119.46c-0.016-14.133-11.469-25.586-25.602-25.602zm-332.8 17.066h102.4v136.54h-102.4zm119.46 0h102.4v136.54h-102.4zm-256 128v-119.46c8e-3 -4.7109 3.8242-8.5273 8.5352-8.5352h110.93v136.54h-110.93c-4.7109-8e-3 -8.5273-3.8242-8.5352-8.5352zm477.87 0c-0.01 4.7109-3.8242 8.5273-8.5352 8.5352h-93.863v-136.54h93.863c4.7109 8e-3 8.5274 3.8242 8.5352 8.5352z"
        />
        <path
          className={classes["color-border-2"]}
          d="m512 477.87c-18.852 0-34.133 15.281-34.133 34.133s15.281 34.133 34.133 34.133 34.133-15.281 34.133-34.133c-0.0195-18.844-15.289-34.113-34.133-34.133zm0 51.199c-9.4258 0-17.066-7.6406-17.066-17.066s7.6406-17.066 17.066-17.066 17.066 7.6406 17.066 17.066c-8e-3 9.4219-7.6445 17.055-17.066 17.066z"
        />
        <path
          d="m816.98 511.5-0.0174-68.085 99.362-0.025 1.732 4.584c1.0844 2.8699 1.5546 27.414 1.2577 65.64-0.45172 58.143-0.6328 61.173-3.7963 63.502-2.6795 1.9729-12.528 2.4483-50.922 2.4579l-47.6 0.012z"
          className={classes["color-1"]}
        />
        <path
          transform="scale(.75)"
          d="m930.3 683.09-0.0224-89.729 134.03-0.0336 0.044 179.46-134.03 0.0335z"
          className={classes["color-1"]}
        />
        <path
          d="m697.72 511.56-0.017-68.054 102-0.0254 0.0167 68.054 0.0168 68.054-102 0.0254z"
          className={classes["color-2"]}
        />
        <path
          d="m577.83 511.76-0.0171-68.255 102.43-0.0254 0.0171 68.255 0.0171 68.255-102.43 0.0254z"
          className={classes["color-3"]}
        />
        <path
          transform="scale(.75)"
          d="m594.45 770.51c-3.6761-2.1399-3.9775-8.8187-3.9975-88.579l-0.0215-86.265 5.1107-1.773c2.8109-0.97512 38.078-1.3639 78.371-0.86402l73.26 0.90892 0.0224 89.494 0.0224 89.494-74.395-0.0505c-46.291-0.0314-75.897-0.9248-78.371-2.3649zm101.24-43.168c29.023-8.067 42.528-46.126 25.127-70.816-14.469-20.53-43.547-26.064-64.402-12.255-10.505 6.9559-20.479 24.809-20.493 36.682-0.0234 20.347 13.456 39.731 31.486 45.278 13.004 4.0005 17.284 4.1687 28.282 1.1118z"
          className={classes["color-bottom"]}
        />
      </svg>
    );
  };

  return (
    <div className={classes["container"]}>
      {renderColorPalette(1)}
      {renderColorPalette(2)}
      {renderColorPalette(3)}
      {renderColorPalette(4)}
    </div>
  );
};

SvgColorPalette.defaultProps = {};

export default SvgColorPalette;
