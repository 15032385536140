import React, { FC } from "react";
import { ContentTile } from "../models/content.model";
import classes from "../styles/components/Tile.module.scss";

interface ITileProps {
  contentTile: ContentTile;
}

const Tile: FC<ITileProps> = (props) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["pictos"]}>
        {props.contentTile.pictosSrc.map((pictoSrc, key) => (
          <img
            src={pictoSrc}
            className={classes["picto"]}
            key={`picto-${key}`}
            alt=""
          />
        ))}
      </div>
      <h3 className={classes["title"]}>{props.contentTile.title}</h3>
      <div
        className={classes["description"]}
        dangerouslySetInnerHTML={{ __html: props.contentTile.description }}
      />
    </div>
  );
};

export default Tile;
