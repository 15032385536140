import React, { createRef, RefObject, useEffect, useState } from "react";
import { GetStaticProps } from "next";
import Head from "next/head";
import Image from "next/image";
import cx from "classnames";
import path from "path";
import { promises as fs } from "fs";
import Link from "next/link";
import BannerParallax from "../components/BannerParallax";
import ConceptionAnimate from "../components/ConceptionAnimate";
import Contact from "../components/Contact";
import Fire from "../components/Fire";
import Portfolio from "../components/Portfolio";
import StickyEcoresponsible from "../components/StickyEcoresponsible";
import SvgColorPalette from "../components/SvgColorPalette";
import TeamMemberLink from "../components/TeamMemberLink";
import Tile from "../components/Tile";
import socialNetworks from "../data/socialNetworks";
import { ContentDevelopmentHow } from "../models/content.model";
import classes from "../styles/pages/Home.module.scss";

interface HomeProps {
  data: any;
}

export const getStaticProps: GetStaticProps = async () => {
  const dataDirectory = path.join(process.cwd(), "data");
  const filename = "data.json";
  const filePath = path.join(dataDirectory, filename);
  const fileContent = await fs.readFile(filePath, "utf8");

  const data = JSON.parse(fileContent);

  // By returning { props: { posts } }, the Blog component
  // will receive `posts` as a prop at build time
  return {
    props: {
      data,
    },
  };
};

export default function Home(props: HomeProps) {
  // const data = props.data.getPagesDocument.data;
  const data = props.data;

  // References
  const refSectionTiles: RefObject<HTMLParagraphElement> = createRef();
  const refSectionConception: RefObject<HTMLParagraphElement> = createRef();
  const refSectionDesignEmphasis: RefObject<HTMLParagraphElement> = createRef();
  const refSectionDev: RefObject<HTMLDivElement> = createRef();
  const refSectionTeam: RefObject<HTMLDivElement> = createRef();
  const refSectionPortfolio: RefObject<HTMLDivElement> = createRef();
  const refSectionContact: RefObject<HTMLDivElement> = createRef();
  const refSectionFooter: RefObject<HTMLDivElement> = createRef();
  const sectionsRefsBgVariants = [
    refSectionTiles,
    refSectionConception,
    refSectionDesignEmphasis,
    refSectionDev,
    refSectionTeam,
    refSectionPortfolio,
    refSectionContact,
    refSectionFooter,
  ];

  // State
  const [backgrounColorVariant, setBackgroundColorVariant] =
    useState<number>(0);

  // TODO : Comment and/or refactor this logic
  // Change background color on scroll
  let sectionsRefsBgVariantsReverse = [...sectionsRefsBgVariants];
  sectionsRefsBgVariantsReverse.reverse();
  useEffect(() => {
    var intersectionObserver = new IntersectionObserver(
      function (entries) {
        const visibleSectionsIndexes: number[] = [];

        const visibleEntries = entries.filter(
          (entry) => entry.intersectionRatio > 0
        );

        for (const entry of visibleEntries) {
          const visibleEntrySectionIndex = sectionsRefsBgVariants.findIndex(
            (section) => section.current === entry.target
          );
          visibleSectionsIndexes.push(visibleEntrySectionIndex);
        }
        setBackgroundColorVariant(Math.max(...visibleSectionsIndexes));
      },
      { threshold: [0, 1] }
    );
    // start observing
    for (const section of sectionsRefsBgVariantsReverse) {
      intersectionObserver.observe(section.current);
    }
    return () => intersectionObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...sectionsRefsBgVariants]);

  const handleOpenChat = () => {
    if (document) {
      const chatwootWidget = document.querySelector(".woot-widget-holder");
      const chatWootBubble: HTMLDivElement = document.querySelector(
        ".woot-widget-bubble"
      );
      if (chatwootWidget?.classList.contains("woot--hide")) {
        chatWootBubble.click();
      }
    }
  };

  return (
    <>
      <Head>
        <title>{data.head.title}</title>
        <meta name="description" content={data.head.metaDescription} />
        <meta property="og:url" content="https://bivouac.io" />
        <meta property="og:title" content={data.head.title} />
        <meta property="og:description" content={data.head.metaDescription} />
        <meta property="og:image" content="https://bivouac.io/og-picture.png" />
      </Head>
      {/* <StickyWip /> */}
      <StickyEcoresponsible stickyData={data.stickyEcoresponsible} />
      <BannerParallax contentBanner={data.banner} />
      <div
        className={cx(
          classes["content"],
          classes[`content--bg-${backgrounColorVariant}`]
        )}
      >
        <div className={classes["introduction"]}>
          <h1 className={classes["glowy"]}>{data.introductionTitle}</h1>
          <Fire />
        </div>
        <div ref={refSectionTiles} className={classes["tiles"]}>
          {data.tiles.map((tile, key) => (
            <Tile contentTile={tile} key={`tile-${key}`} />
          ))}
        </div>
        <div className={classes["workflow"]}>
          {backgrounColorVariant < 7 && (
            <>
              <div className={classes["stars"]} />
              <div className={classes["twinkling"]} />
            </>
          )}

          <div
            ref={refSectionConception}
            className={cx(classes["section"], classes["section--workflow"])}
          >
            <h2 className={cx(classes["section__title"], classes["glowy"])}>
              {data.workflowTitle}
            </h2>
            <p
              className={classes["section__body"]}
              dangerouslySetInnerHTML={{ __html: data.workflowDescription }}
            ></p>
          </div>
          <div
            className={cx(classes["section"], classes["section--conception"])}
          >
            <div className={classes["section__body"]}>
              <h3 className={cx(classes["section__title"], classes["glowy"])}>
                {data.sections.conception.title}
              </h3>
              <ConceptionAnimate>
                <p className={"text-emphasis"}>
                  {data.sections.conception.emphasis}
                </p>
              </ConceptionAnimate>
              <ConceptionAnimate>
                <p>{data.sections.conception.content}</p>
              </ConceptionAnimate>
              <div className={classes["section__how"]}>
                <div>
                  <ConceptionAnimate>
                    <h4>Comment?</h4>
                  </ConceptionAnimate>
                  <ConceptionAnimate>
                    <p className={cx(classes["section__how-body"])}>
                      {data.sections.conception.how}
                    </p>
                  </ConceptionAnimate>
                </div>
                <div className={classes["section__how-picto-wrap"]}>
                  <ConceptionAnimate type="image">
                    <img
                      className={classes["section__how-picto"]}
                      src="/lightbulb.svg"
                      alt=""
                    />
                  </ConceptionAnimate>
                </div>
              </div>
            </div>
          </div>
          <div className={cx(classes["section"], classes["section--design"])}>
            <div className={classes["section__body"]}>
              {/* <DesignAnimate refAnchor={refSectionDesignEmphasis} type={1} />
              <DesignAnimate refAnchor={refSectionDesignEmphasis} type={2} /> */}
              <div className={classes["svg-color-palette"]}>
                <SvgColorPalette />
              </div>

              <h3 className={cx(classes["section__title"], classes["glowy"])}>
                {data.sections.design.title}
              </h3>
              <p ref={refSectionDesignEmphasis} className={"text-emphasis"}>
                {data.sections.design.emphasis}
              </p>
              <p>{data.sections.design.content}</p>
              <div className={classes["section__how"]}>
                <div>
                  <h4>Comment?</h4>
                  <p className={cx(classes["section__how-body"])}>
                    {data.sections.design.how}
                  </p>
                </div>
                <div className={classes["section__how-picto-wrap"]}>
                  <img
                    className={classes["section__how-picto"]}
                    src="/lightbulb.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            ref={refSectionDev}
            className={cx(classes["section"], classes["section--dev"])}
          >
            <div className={cx(classes["section__body"])}>
              {/* <DevAnimate refAnchor={refSectionDev}> */}
              <h3 className={cx(classes["section__title"], classes["glowy"])}>
                {data.sections.development.title}
              </h3>
              <p className={cx("text-emphasis", classes["section__emphasis"])}>
                {data.sections.development.emphasis}
              </p>
              <p className={classes["section__content"]}>
                {data.sections.development.content}
              </p>
              <div className={classes["section__how"]}>
                <div>
                  <h4 className={classes["section__how-title"]}>Comment?</h4>
                  {(
                    data.sections.development.how as ContentDevelopmentHow[]
                  ).map((sectionHow, index) => (
                    <div
                      className={cx("text-code", classes["dev-how"])}
                      key={`sectionHow-${index}`}
                    >
                      <h5 className={cx(classes["dev-how__title"])}>
                        .{sectionHow.title}
                      </h5>
                      <div className={cx(classes["dev-how__description"])}>
                        {"{"}
                        <p>{sectionHow.description}</p>
                        {"}"}
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes["section__how-picto-wrap"]}>
                  <img
                    className={classes["section__how-picto"]}
                    src="/lightbulb.svg"
                    alt=""
                  />
                </div>
              </div>
              <div className={classes["section__our-tech"]}>
                <h4 className={classes["section__how-title"]}>
                  {data.sections.development.technologiesTitle}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.sections.development.technologiesDescription,
                  }}
                />
                <div className={classes["section__tech-img"]}>
                  {data.sections.development.technologies.map(
                    (technology, index) => (
                      <a
                        key={index}
                        rel="noreferrer"
                        href={technology.url}
                        target="_blank"
                        className={classes["section__tech-url"]}
                      >
                        <Image
                          aria-label={technology.name}
                          src={technology.pictureSrc}
                          width={120}
                          height={120}
                          layout="fixed"
                          alt=""
                        />
                      </a>
                    )
                  )}
                </div>
              </div>
              {/* </DevAnimate> */}
            </div>
          </div>
        </div>

        <div
          ref={refSectionTeam}
          className={cx(classes["section"], classes["section--team"])}
        >
          <div className={classes["section__body"]}>
            <h3 className={classes["section__title"]}>
              {data.sections.team.title}
            </h3>
            <div className={classes["team"]}>
              {data.sections.team.members.map((teamMember, index) => (
                <div
                  key={`teamMember-${index}`}
                  className={classes["team__member"]}
                >
                  <Image
                    src={teamMember.pictureSrc}
                    className={classes["member__image"]}
                    width={220}
                    height={220}
                    quality={90}
                    alt={`Photo de ${teamMember.name}`}
                  />
                  <h4 className={classes["member__name"]}>{teamMember.name}</h4>
                  <div className={classes["member__profession"]}>
                    {teamMember.profession}
                  </div>
                  <div className={classes["member__passion"]}>
                    {teamMember.passion}
                  </div>
                  <div className={classes["member__links"]}>
                    {teamMember.links.map((teamMemberLink, indexLink) => (
                      <TeamMemberLink
                        key={`teamMemberLink-${indexLink}`}
                        link={teamMemberLink}
                        teamMember={teamMember}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          ref={refSectionPortfolio}
          className={cx(classes["section"], classes["section--portfolio"])}
        >
          <div className={classes["section__header"]}>
            <h3 className={classes["section__title"]}>
              {data.sections.portfolio.title}
            </h3>
          </div>
          <div className={classes["section__body"]}>
            <Portfolio items={data.sections.portfolio.items} />
          </div>
        </div>
        <div
          ref={refSectionContact}
          className={cx(classes["section"], classes["section--contact"])}
        >
          <div className={classes["section__body"]}>
            <h3 className={classes["section__title"]}>
              {data.sections.contact.title}
            </h3>
            <Contact
              description={data.sections.contact.description}
              handleOpenChat={handleOpenChat}
            />
          </div>
        </div>
        <div className={classes["footer-pic"]}>
          <Image src="/footer.png" layout="fill" objectFit="cover" alt="" />
          <span className={classes["footer-pic__credits"]}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.behance.net/rajeshlathi"
            >
              Illustration by Rajesh Lathi
            </a>{" "}
          </span>
        </div>
        <div ref={refSectionFooter} className={classes["footer"]}>
          <div className={classes["footer__links"]}>
            <div className={classes["footer__link"]}>
              <Link href="/mentions-legales">Mentions légales</Link>
            </div>
            <div className={classes["footer__link"]}>
              <Link href="/confidentialite">Politique de confidentialité</Link>
            </div>
            <div>@2024 - Agence Bivouac.io</div>
          </div>
          <div className={classes["footer__socialNetworks"]}>
            {socialNetworks.map((network, index) => (
              <div key={index} className={classes["footer__socialIcon"]}>
                <a href={network.link} aria-label={network.name}>
                  <Image src={network.pictoSrc} width={40} height={60} alt="" />
                </a>
              </div>
            ))}
          </div>
          <div>
            <div className={classes["goodIt"]}>
              <div className={classes["goodIt__text"]}>
                Bivouac est membre du collectif GoodIT
              </div>
              <a
                href="https://www.good-it.org/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes["goodIt__logo"]}
              >
                <img
                  src={data.sections.footer.goodIT.logoSrc}
                  alt="Logo Good IT"
                  width="60px"
                  height="60px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
