import { createRef, FC, RefObject, useEffect } from "react";
import Image from "next/image";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import cx from "classnames";
import { ContentPortfolioItem } from "../models/content.model";
import classes from "../styles/components/Portfolio.module.scss";

interface IPortfolioProps {
  items: ContentPortfolioItem[];
}

const Portfolio: FC<IPortfolioProps> = (props) => {
  const refScroller: RefObject<HTMLDivElement> = createRef();
  const refItem: RefObject<HTMLDivElement> = createRef();

  let itemWidth;
  function setItemWidth() {
    if (refItem.current) {
      itemWidth = refItem.current.clientWidth;
    }
  }
  useEffect(() => {
    setItemWidth();
    window.addEventListener("resize", setItemWidth);
  });

  function handleNavigation(direction: "left" | "right") {
    if (refScroller.current) {
      const currentScrollValue = refScroller.current.scrollLeft;
      const scrollWidth = refScroller.current.scrollWidth;
      let nextScrollValue;
      if (direction === "left") {
        if (currentScrollValue === 0) {
          // This is the first item. Go to last item by setting scroll position to scroller width
          nextScrollValue = scrollWidth;
        } else {
          // Go to previous item
          nextScrollValue = -itemWidth;
        }
      }
      if (direction === "right") {
        if (Math.ceil(currentScrollValue) >= scrollWidth - itemWidth) {
          // Last item reached. Go back to first item by setting scroll position to 0

          nextScrollValue = -scrollWidth;
        } else {
          // Go to next item
          nextScrollValue = itemWidth;
        }
      }
      refScroller.current.scrollBy({
        left: nextScrollValue,
        top: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <div className={classes["container"]}>
      <FiChevronLeft
        className={cx(classes["chevron"], classes["chevron--left"])}
        onClick={() => handleNavigation("left")}
      />
      <div ref={refScroller} className={classes["scroller"]}>
        {props.items.map((item, indexItem) => (
          <div
            className={cx(classes["item"], classes["item--active"])}
            key={`portfolioItem-${indexItem}`}
            ref={indexItem === 0 ? refItem : null}
          >
            <Image
              className={classes["item__picture"]}
              src={item.pictureSrc}
              width={800}
              height={450}
              alt={`Capture d'écran du site ${item.title}`}
            />
            <div className={classes["item__content"]}>
              <h4 className={classes["item__title"]}>{item.title}</h4>
              <div
                className={classes["item__description"]}
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              />
              <a
                className={classes["item__link"]}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Découvrir le site internet
              </a>
            </div>
          </div>
        ))}
      </div>
      <FiChevronRight
        className={cx(classes["chevron"], classes["chevron--right"])}
        onClick={() => handleNavigation("right")}
      />
    </div>
  );
};

Portfolio.defaultProps = {};

export default Portfolio;
