import React, { FC } from "react";
import classes from "../styles/components/Fire.module.scss";

interface IFireProps {}

const Fire: FC<IFireProps> = (props) => {
  return (
    <div className={classes["container"]}>
      <div className={classes["fire"]}>
        <div className={classes["fire-left"]}>
          <div className={classes["main-fire"]}></div>
          <div className={classes["particle-fire"]}></div>
        </div>
        <div className={classes["fire-main"]}>
          <div className={classes["main-fire"]}></div>
          <div className={classes["particle-fire"]}></div>
        </div>
        <div className={classes["fire-right"]}>
          <div className={classes["main-fire"]}></div>
          <div className={classes["particle-fire"]}></div>
        </div>
        <div className={classes["fire-bottom"]}>
          <div className={classes["main-fire"]}></div>
        </div>
      </div>
    </div>
  );
};

Fire.defaultProps = {};

export default Fire;
